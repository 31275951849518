import React from "react";
import { Link } from "gatsby";
import github from "../img/github-icon.svg";
import logo from "../img/logo.svg";
import { FaRegEnvelope, FaPhone } from "react-icons/fa";

const ContactHeaderBar = class extends React.Component {
  componentDidMount() {
    // // Get all "navbar-burger" elements
    // const $navbarBurgers = Array.prototype.slice.call(
    //     document.querySelectorAll('.navbar-burger'),
    //     0
    // )
    // // Check if there are any navbar burgers
    // if ($navbarBurgers.length > 0) {
    //     // Add a click event on each of them
    //     $navbarBurgers.forEach(el => {
    //         el.addEventListener('click', () => {
    //             // Get the target from the "data-target" attribute
    //             const target = el.dataset.target
    //             const $target = document.getElementById(target)
    //
    //             // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    //             el.classList.toggle('is-active')
    //             $target.classList.toggle('is-active')
    //         })
    //     })
    // }
  }

  render() {
    const phone = this.props.phone || "01284 598008";
    const email = this.props.email || "info@lignaconsultancy.co.uk";
    return (
      <div className={"contact-header"}>
        <div className={"container contact-container is-hidden-touch"}>
          <div className={"columns"}>
            <div className={"column"}></div>
            <div className={"column contact-white-column"}></div>
            <div className={"column is-narrow contact-column"}>
              <div className={"contact-item"}>
                <a className={"font-white"} href={`mailto:${email}`}>
                  {email}
                </a>
              </div>
              <div className={"fa-icon"}>
                <FaRegEnvelope />
              </div>

              <div className={"contact-item"}>
                <a className={"font-white"} href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
              <div className={"fa-icon"}>
                <FaPhone />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ContactHeaderBar;
