import React from 'react'
import {Link} from 'gatsby'

// import logo from '../img/logo.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'
import logo from '../img/ligna-logo.png';

const Footer = class extends React.Component {
    render() {
        return (
            <footer className="footer darkest-green-bg has-text-white-ter mb-2">
                <div className="content has-text-centered">
                    <img
                        src={logo}
                        alt="Kaldi"
                        style={{width: '14em'}}
                    />
                </div>
                <div className="content has-text-centered has-text-white-ter">
                    <div className="container has-text-white-ter">
                        <div className="columns">
                            <div className="column is-3 pr-4">
                                <section className="menu has-text-left">
                                    <div className={'is-ligna-font font-primary menu-title has-text-left'}>Hours &
                                        Info
                                    </div>
                                    <ul className="menu-list font-white">
                                        <li><span>Office</span>
                                            <a href={'tel:01284598008'} className={'is-pulled-right'}> 01284 598008</a>
                                        </li>
                                        <li><span>Mon - Fri</span>
                                            <div className={'is-pulled-right'}>9:00am - 5:00pm</div>
                                        </li>
                                        <li><span>Sat</span>
                                            <div className={'is-pulled-right'}>Closed</div>
                                        </li>
                                        <li><span>Sun</span>
                                            <div className={'is-pulled-right'}>Closed</div>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                            <div className="column is-4">
                                <section className="menu">
                                    <div className={'is-ligna-font font-primary menu-title has-text-left'}>Useful Info
                                    </div>
                                    <ul className="menu-list font-white">
                                        <li><Link className="navbar-item" to="/trees-and-development/bs-5837-tree-survey">BS5837 Tree Surveys</Link></li>
                                        <li><Link className="navbar-item" to="/trees-and-development/tree-protection-scheme">Tree Protection Schemes</Link></li>
                                        <li><Link className="navbar-item" to="/trees-and-development/pre-design-advice">Pre-design Advice</Link></li>
                                        <li><Link className="navbar-item" to="/trees-and-development/impact-assessment">Arboricultural Impact Assessments</Link></li>
                                    </ul>
                                </section>
                            </div>
                            <div className="column">
                                <section className="menu">
                                    <div className={'is-ligna-font font-primary menu-title has-text-left'}>Links</div>
                                    <ul className="menu-list font-white">
                                        <li><Link className="navbar-item" to="/about">About Us</Link></li>
                                        <li><Link className="navbar-item" to="/trees-and-development">Trees & Development</Link></li>
                                        <li><Link className="navbar-item" to="/soft-landscaping">Soft Landscaping</Link></li>
                                        <li><Link className="navbar-item" to="/contact">Contact</Link></li>
                                    </ul>
                                </section>
                            </div>
                            <div className="column social">
                                <section className="menu">
                                    <div className={'is-ligna-font font-primary menu-title has-text-left'}>Social</div>
                                    <div className={'social-links'}>
                                        <a title="facebook" href="https://www.facebook.com/lignaconsultancy/"
                                           target="_blank" rel="noopener noreferrer" >
                                            <img
                                                src={facebook}
                                                alt="Facebook"
                                                style={{width: '1em', height: '1em'}}
                                            />
                                        </a>
                                        <a title="twitter" href="https://twitter.com/@lignaconsult/"
                                           target="_blank" rel="noopener noreferrer" >
                                            <img
                                                className="fas fa-lg"
                                                src={twitter}
                                                alt="Twitter"
                                                style={{width: '1em', height: '1em'}}
                                            />
                                        </a>
                                    {/*</div>*/}
                                    {/*<div className={'social-links'}>*/}
                                        <a title="linkedin" href="https://www.linkedin.com/company-beta/15081174/"
                                           target="_blank" rel="noopener noreferrer" >
                                            <i className={'fab fa-linkedin-in fa-lg font-dark-green'} style={{width: '1em', height: '1em', verticalAlign: 0}}
                                            />
                                        </a>
                                    </div>
                                </section>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
