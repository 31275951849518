import React from "react";
import { Link } from "gatsby";
import github from "../img/github-icon.svg";
import logo from "../img/logo.svg";
import { FaRegEnvelope, FaPhone } from "react-icons/fa";

const ContactHeaderBar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }

  toggleModal(bool) {
    this.setState({ modalOpen: bool });
  }

  render() {
    return (
      <>
        <div
          className={"header-banner has-text-centered"}
          style={{
            background: "#AB3900",
            padding: "8px",
            color: "#fff"
          }}
        >
              <p>
                <strong style={{ color: "#ffffff" }}>COVID-19</strong> - We have
                adapted our working practices to continue working in-line with
                the government advice.
                <a
                  href={"#"}
                  onClick={this.toggleModal.bind(this, true)}
                  style={{
                    padding: "15px 20px",
                    margin: "-15px",
                    color: "#ffffff",
                      textDecoration: "underline"
                  }}
                >
                  More Information
                </a>
              </p>
        </div>
        <div className={"modal" + (this.state.modalOpen ? " is-active" : "")}>
          <div
            className="modal-background"
            onClick={this.toggleModal.bind(this, false)}
          ></div>
          <div className="modal-content">
            <h4>COVID-19</h4>
            <p>
              We have adapted our working practices to be in-line with the
              government's current advice, and as such are still able to provide
              Tree Surveys and Reports for new and existing clients.
            </p>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={this.toggleModal.bind(this, false)}
          ></button>
        </div>
      </>
    );
  }
};

export default ContactHeaderBar;
