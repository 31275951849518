import React from 'react'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import './all.sass'
import './non-purged.sass'
import ContactHeaderBar from "./ContactHeaderBar";
import Banner from "./Banner";

const TemplateWrapper = ({phone, email, children}) => (
    <StaticQuery
        query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
        render={data => (
            <div>
                <Helmet>
                    <html lang="en"/>
                    <title>{data.site.siteMetadata.title}</title>
                    <meta
                        name="description"
                        content={data.site.siteMetadata.description}
                    />

                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/img/logo-square-touch.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        href="/img/favicon-32x32.png"
                        sizes="32x32"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        href="/img/favicon-16x16.png"
                        sizes="16x16"
                    />
                    <meta name="theme-color" content="#fff"/>

                    <meta property="og:type" content="business.business"/>
                    <meta property="og:title" content={data.site.siteMetadata.title}/>
                    <meta property="og:url" content="/"/>
                    <meta property="og:image" content="/img/ligna-logo-small.png"/>
                    <link rel="stylesheet" href="https://use.typekit.net/wwy3qpv.css"/>
                    <script src="https://kit.fontawesome.com/1d75de0f4f.js" crossOrigin="anonymous"></script>
                </Helmet>
                <ContactHeaderBar phone={phone} email={email}/>
                <Navbar/>
                {/*<Banner/>*/}
                <div>{children}</div>
                <Footer/>
            </div>
        )}
    />
)

export default TemplateWrapper
