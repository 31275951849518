import React from 'react'
import {Link} from 'gatsby'
import {Match} from '@reach/router'
import github from '../img/github-icon.svg'
import oldLogo from '../img/logo.svg'
import logo from '../img/ligna-logo.png'
import * as _ from 'lodash'

const Navbar = class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    componentDidMount() {
        // Get all "navbar-burger" elements
        const $navbarBurgers = Array.prototype.slice.call(
            document.querySelectorAll('.navbar-burger'),
            0
        )
        // Check if there are any navbar burgers
        if ($navbarBurgers.length > 0) {
            // Add a click event on each of them
            $navbarBurgers.forEach(el => {
                el.addEventListener('click', () => {
                    // Get the target from the "data-target" attribute
                    const target = el.dataset.target
                    const $target = document.getElementById(target)

                    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                    el.classList.toggle('current-item')
                    $target.classList.toggle('current-item')
                })
            })
        }
    }

    toggleMenu() {
        this.setState({open: !this.state.open});
    }

    render() {
        return (
            <nav
                className="navbar is-transparent"
                role="navigation"
                aria-label="main-navigation"
            >
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item navbar-logo" title="Ligna Logo">
                            <img src={logo} alt="LignaLogo"/>
                        </Link>
                        {/* Hamburger menu */}
                        <div className={"navbar-burger burger my-auto" + (this.state.open ? " is-active" : "")}
                             data-target="navMenu" onClick={this.toggleMenu.bind(this)}>
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <div id="navMenu" className={"navbar-menu" + (this.state.open ? " is-active" : "")}>
                        <div className="navbar-start has-text-centered">

                        </div>
                        <div className="navbar-end has-text-centered">

                            <Link className="navbar-item" to="/" activeClassName={'current-item'}>
                                Home
                            </Link>
                            <Link className="navbar-item" to="/about" activeClassName={'current-item'}>
                                About Us
                            </Link>
                            <Match path={'*'}>
                                {props => {
                                    return <div className={"navbar-item has-dropdown is-hoverable" + (_.includes(['/trees-and-development', '/soft-landscaping', '/tree-risk-management'], props.location.pathname) ? ' current-item' : '')}>
                                        <a className={"navbar-link cursor-default"}>
                                            Services
                                        </a>


                                        <div className="navbar-dropdown">
                                            <Link className="navbar-item" to="/trees-and-development"
                                                  activeClassName={'current-item'}>
                                                Trees & Development
                                            </Link>
                                            <Link className="navbar-item" to="/tree-risk-management"
                                                  activeClassName={'current-item'}>
                                                Tree Risk Management
                                            </Link>
                                            <Link className="navbar-item" to="/tree-surveys-and-inspections"
                                                  activeClassName={'current-item'}>
                                                Tree Surveys & Inspections
                                            </Link>
                                            <Link className="navbar-item" to="/soft-landscaping"
                                                  activeClassName={'current-item'}>
                                                Soft Landscaping Schemes
                                            </Link>
                                        </div>
                                    </div>
                                }}
                            </Match>
                            <Link className="navbar-item" to="/careers" activeClassName={'current-item'}>
                                Careers
                            </Link>
                            <Link className="navbar-item" to="/contact" activeClassName={'current-item'}>
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
